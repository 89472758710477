import { notification } from "antd";
import React, { FC } from "react";

export const NotificationContext = React.createContext<any>({});

export const NotificationContextProvider: FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [api, contextHolder] = notification.useNotification();

  return (
    <NotificationContext.Provider value={{ name: "DMN Playground", api }}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
