import { Button, Drawer, Form, Input, Space, Popconfirm } from "antd";
import { FC, useEffect, useState } from "react";
import { useNotify } from "../hooks/useNotify";
import { useCreateDmn } from "../api/dmn.api";
import { CreateDmnPayload, DMN } from "../types";
import { isJsonStringValid } from "../common";

type CreateDmnProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  defaultDmn?: DMN;
};
export const CreateDmn: FC<CreateDmnProps> = ({
  open,
  setOpen,
  defaultDmn,
}) => {
  const [validSample, setValidSample] = useState<boolean>(true);
  const [validInterce, setValidInterface] = useState<boolean>(true);
  const [validCustom, setValidCustom] = useState<boolean>(true);

  const { showError } = useNotify();

  const [form] = Form.useForm();

  const { isLoading, createDmn } = useCreateDmn(() => {
    setOpen(false);
  });

  const onSubmit = async (values: any) => {
    console.log("values", values);

    if (!validSample || !validInterce || !validCustom) {
      showError(
        "Some fields has an error, please validate you are using a valid json object."
      );
      return;
    }

    const payload: CreateDmnPayload = {
      name: values?.name,
      filename: values?.filename,
      contextInterface: values.contextInterface,
      sampleContext: values.sampleContext,
      description: values.description,
      customData: values.customData,
    };

    createDmn(payload);
  };

  useEffect(() => {
    if (!defaultDmn) {
      form.setFieldValue("name", "");
      form.setFieldValue("filename", "");
      form.setFieldValue("description", "");
      form.setFieldValue("contextInterface", "");
      form.setFieldValue("customData", "");
      form.setFieldValue("sampleContext", "");
      return;
    }

    const { customData, sampleContext, contextInterface } = defaultDmn;

    form.setFieldValue("name", defaultDmn.name);
    form.setFieldValue("filename", defaultDmn.filename);
    form.setFieldValue("description", defaultDmn.description);
    form.setFieldValue(
      "contextInterface",
      typeof contextInterface === "string"
        ? contextInterface
        : JSON.stringify(contextInterface ?? {})
    );
    form.setFieldValue(
      "customData",
      typeof customData === "string"
        ? customData
        : JSON.stringify(customData ?? {})
    );
    form.setFieldValue(
      "sampleContext",
      typeof sampleContext === "string"
        ? sampleContext
        : JSON.stringify(sampleContext ?? {})
    );
  }, [defaultDmn]);

  return (
    <Drawer
      title={`${defaultDmn ? "Edit DMN" : "Create new DMN"}`}
      placement="right"
      onClose={() => setOpen(false)}
      open={open}
      width={520}
      destroyOnClose
      extra={
        <Space>
          <Button
            type={"primary"}
            loading={isLoading}
            onClick={() => form.submit()}
          >
            {defaultDmn ? "Edit" : "Create"}
          </Button>
        </Space>
      }
    >
      <Form form={form} onFinish={(values: any) => onSubmit(values)}>
        <Form.Item
          label="Name"
          name={"name"}
          rules={[{ required: true, message: "Please input the name" }]}
        >
          <Input placeholder="DMN name..." />
        </Form.Item>
        <Form.Item
          label="Filename"
          name={"filename"}
          rules={[{ required: true, message: "Please input the filename" }]}
        >
          <Input placeholder="sample.dmn" />
        </Form.Item>
        <Form.Item label="Description" name={"description"}>
          <Input placeholder="Describe what does your DMN model do..." />
        </Form.Item>
        <Form.Item label="Sample Context" name={"sampleContext"}>
          <Input.TextArea
            rows={4}
            placeholder="Sample context to allow others use this DMN model easily."
            onChange={(e: any) =>
              setValidSample(isJsonStringValid(e.target.value))
            }
            status={validSample ? undefined : "error"}
          />
        </Form.Item>
        <Form.Item label="Context Interface" name={"contextInterface"}>
          <Input.TextArea
            rows={4}
            placeholder="Describe the required context/data format so others can use your model as it is supposed to be used."
            onChange={(e: any) =>
              setValidInterface(isJsonStringValid(e.target.value))
            }
            status={validInterce ? undefined : "error"}
          />
        </Form.Item>
        <Form.Item label="Custom Data" name={"customData"}>
          <Input.TextArea
            rows={4}
            placeholder="If your model will have customData that you want to inject in the 'context' when executing it."
            onChange={(e: any) => {
              console.log("e.target.value", e.target.value);
              setValidCustom(isJsonStringValid(e.target.value));
            }}
            status={validCustom ? undefined : "error"}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CreateDmn;
