import {
  Select,
  Input,
  Card,
  Empty,
  Button,
  Drawer,
  Tabs,
  TabsProps,
  Spin,
  Typography,
  Popconfirm,
  Form,
  Switch,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { useDeleteDmn, useDmns, useExecuteDmn } from "../api/dmn.api";
import ReactJson from "react-json-view";
import { useNotify } from "../hooks/useNotify";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import UploadDmnFile from "../components/UploadDmnFile";
import { useDownloadDmn } from "../api/download";
import moment from "moment";
import CreateDmn from "../components/CreateDmn";
import { DMN } from "../types";
import { isJsonStringValid } from "../common";

const { TextArea } = Input;

const HomePage = () => {
  const [dmn, setDmn] = useState<string>();
  const [context, setContext] = useState<string>();
  const [openDocs, setOpenDocs] = useState<boolean>(false);
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [_dmnResult, setDmnResult] = useState<any>();
  const [_editDmn, setEditDmn] = useState<DMN>();
  const [validTextArea, setValidTextArea] = useState<boolean>(true);
  const [debug, setDebug] = useState<boolean>(true);
  const [showDesc, setShowDesc] = useState<boolean>(false);

  const { isLoading, data: dmns } = useDmns();
  const {
    isLoading: loadingExecute,
    data: dmnResult,
    error,
    executeDmn,
  } = useExecuteDmn();

  useEffect(() => {
    if (error) {
      console.log("Execute error: ", error);
    }
  }, [error]);

  const { download, isLoading: loadinDownload } = useDownloadDmn();

  const cleanup = () => {
    setContext(undefined);
    setDmnResult(undefined);
  };

  const { isLoading: loadingDelete, deleteDmn } = useDeleteDmn(() => {
    cleanup();
    setDmn(undefined);
  });

  const { showError } = useNotify();

  const dmnsItems = useMemo(() => {
    return (dmns ?? []).map((dmn) => {
      return { label: dmn.name, value: dmn.filename };
    });
  }, [dmns]);

  const enableExecute = useMemo(() => {
    return !!dmn && !!context;
  }, [dmn, context]);

  const selectedDmn = useMemo(() => {
    return (dmns ?? []).find((d) => d.filename === dmn);
  }, [dmns, dmn]);

  const currentContextInterfaceObj = useMemo(() => {
    if (!selectedDmn?.contextInterface) {
      return undefined;
    }
    if (typeof selectedDmn.contextInterface === "string") {
      try {
        return JSON.parse(selectedDmn.contextInterface);
      } catch (e: any) {
        showError("Invalid JSON configuration for contextInterface.");
        return {};
      }
    }

    return selectedDmn.contextInterface;
  }, [selectedDmn]);

  const currentSampleContextObj = useMemo(() => {
    if (!selectedDmn?.sampleContext) {
      return undefined;
    }
    if (typeof selectedDmn.sampleContext === "string") {
      try {
        return JSON.parse(selectedDmn.sampleContext);
      } catch (e: any) {
        showError("Invalid JSON configuration for sampleContext.");
        return {};
      }
    }

    return selectedDmn.sampleContext;
  }, [selectedDmn]);

  const dmnDocsTabs: TabsProps["items"] = useMemo(() => {
    return [
      {
        key: "0",
        label: `Description`,
        children: selectedDmn?.description ? selectedDmn.description : "N/A",
      },
      {
        key: "1",
        label: `Interface`,
        children: currentContextInterfaceObj ? (
          <ReactJson src={currentContextInterfaceObj} />
        ) : (
          "N/A"
        ),
      },
      {
        key: "2",
        label: `Sample Context`,
        children: currentSampleContextObj ? (
          <ReactJson src={currentSampleContextObj} />
        ) : (
          "N/A"
        ),
      },
    ];
  }, [selectedDmn]);

  const handleSampleContext = () => {
    const sampleCtx = selectedDmn?.sampleContext;
    if (!sampleCtx) {
      showError("No sample context defined for this DMN.");
      return;
    }

    setContext(
      typeof sampleCtx == "object" ? JSON.stringify(sampleCtx) : sampleCtx
    );
  };

  const onExecute = () => {
    if (!selectedDmn) {
      showError("Please select a DMN first.");
      return;
    }

    const filename = selectedDmn.filename;

    if (!filename) {
      showError("Missing filename property for the DMN.");
      return;
    }

    executeDmn({
      dmnFile: filename,
      ctx: JSON.parse(context ?? "{}"),
      debug,
      showDesc,
    });
  };

  useEffect(() => {
    setDmnResult(dmnResult);
  }, [dmnResult]);

  const handleEdit = (dmn: DMN) => {
    setOpenCreate(true);
    setEditDmn(dmn);
  };

  const handleTextAreaChange = (val: string) => {
    setContext(val);
    setValidTextArea(isJsonStringValid(val));
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "8px",
        }}
      >
        <Button
          type={"primary"}
          onClick={() => {
            setEditDmn(undefined);
            setOpenCreate(true);
          }}
          style={{ marginRight: "8px" }}
        >
          + New Model
        </Button>
      </Row>
      <Row>
        <Col xs={12} md={3} lg={3}>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <Select
                value={dmn}
                onChange={(val: string) => {
                  setDmn(val);
                  cleanup();
                }}
                options={dmnsItems}
                style={{ width: "100%", marginBottom: "8px" }}
                placeholder={"Select DMN"}
              />
            </Col>
            <Col xs={6} md={6} lg={6}>
              <Button
                type={"primary"}
                block
                disabled={!selectedDmn}
                onClick={() => handleEdit(selectedDmn!)}
              >
                Edit
              </Button>
            </Col>
            <Col xs={6} md={6} lg={6}>
              <Popconfirm
                title="Delete the DMN"
                description={`Are you sure to delete "${selectedDmn?.name}" DMN model?`}
                onConfirm={() => deleteDmn(selectedDmn!.filename)}
                okText="I'm sure"
                cancelText="No"
              >
                <Button
                  block
                  danger
                  disabled={!selectedDmn}
                  loading={loadingDelete}
                >
                  Remove
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={9} lg={9}>
          <TextArea
            style={{ width: "100%" }}
            placeholder="Context..."
            value={context}
            rows={4}
            onChange={(e: any) => handleTextAreaChange(e.target.value)}
            status={validTextArea ? undefined : "error"}
          />
          <Button
            disabled={!dmn}
            onClick={() => handleSampleContext()}
            style={{ marginRight: "4px" }}
          >
            Use sample context
          </Button>
          <Button
            disabled={!dmn}
            onClick={() => setOpenUpload(true)}
            style={{ margin: "4px" }}
          >
            Upload <UploadOutlined />
          </Button>
          <Button
            disabled={!dmn}
            loading={loadinDownload}
            onClick={() => download(selectedDmn?.filename!)}
            style={{ margin: "4px" }}
          >
            Download <DownloadOutlined />
          </Button>
          <Button
            disabled={!dmn}
            onClick={() => setOpenDocs(true)}
            style={{ margin: "4px" }}
          >
            View docs
          </Button>
          <Button
            loading={loadingExecute}
            type={"primary"}
            disabled={!enableExecute}
            onClick={() => onExecute()}
            style={{ margin: "4px" }}
          >
            Execute
          </Button>
          <Switch
            onChange={(val: boolean) => setDebug(val)}
            checked={debug}
            checkedChildren={"Show debug info"}
            unCheckedChildren={"Hide debug info"}
            style={{ margin: "4px" }}
          />
          <Switch
            onChange={(val: boolean) => setShowDesc(val)}
            checked={showDesc}
            checkedChildren={"Show descriptions"}
            unCheckedChildren={"Hide descriptions"}
            style={{ margin: "4px" }}
          />
        </Col>
      </Row>
      {!!selectedDmn && (
        <Row middle="xs">
          <Col>
            {" "}
            <Typography.Text strong>Last execution Date: </Typography.Text>
          </Col>
          <Col style={{ marginLeft: "8px" }}>
            {selectedDmn?.lastExecutionDate
              ? moment(selectedDmn.lastExecutionDate).format(
                  "MM/DD/YYYY hh:mm a"
                )
              : "It has never been run before."}
          </Col>
        </Row>
      )}
      <Row>
        <Card title={"DMN Result"} style={{ marginTop: "32px", width: "100%" }}>
          {loadingExecute && <Spin />}
          {!_dmnResult && !loadingExecute && (
            <Empty
              description={
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    Select the DMN, add the context and then click "execute"
                  </Col>
                </Row>
              }
            />
          )}
          {!!_dmnResult && (
            <ReactJson
              src={
                typeof _dmnResult == "string"
                  ? JSON.parse(_dmnResult)
                  : _dmnResult
              }
            />
          )}
        </Card>
      </Row>
      <Drawer
        title={`${selectedDmn?.name ?? ""} Docs`}
        placement="right"
        onClose={() => setOpenDocs(false)}
        open={openDocs}
      >
        <Tabs items={dmnDocsTabs} />
      </Drawer>

      {selectedDmn && (
        <UploadDmnFile
          name={selectedDmn.name}
          open={openUpload}
          setOpen={setOpenUpload}
          filename={selectedDmn.filename}
        />
      )}
      <CreateDmn
        open={openCreate}
        setOpen={setOpenCreate}
        defaultDmn={_editDmn}
      />
    </>
  );
};

export default HomePage;
