import axios from "axios";
import { useApiUrl } from "../hooks/useApiUrl";
import { useState } from "react";

export const useUploadDmn = (onSuccess?: Function, onError?: Function) => {
  const apiUrl = useApiUrl();
  const [isLoading, setLoading] = useState<boolean>(false);

  const upload = async (filename: string, formData: FormData) => {
    setLoading(true);
    try {
      const upload = await axios({
        url: `${apiUrl}/v2/dmn/${filename}/upload`,
        method: "POST",
        data: formData,
      });

      if (onSuccess) {
        onSuccess();
      }

      return upload?.data;
    } catch (e: any) {
      if (onError) {
        onError();
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    upload,
    isLoading,
  };
};
