import { useContext } from "react";
import { NotificationContext } from "../context/Notification.context";

export const useNotify = () => {
  const { api } = useContext(NotificationContext);

  return {
    showError: (msg: string) =>
      api.open({
        type: "error",
        message: msg ?? "Por favor, inténtelo de nuevo.",
        placement: "bottom",
        duration: 10,
      }),
    showSuccess: (msg: string) =>
      api.open({
        type: "success",
        message: msg,
        placement: "bottom",
      }),
  };
};
