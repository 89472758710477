import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import SpinFC from "antd/lib/spin";
import HomePage from "./pages/Home.page";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { NotificationContextProvider } from "./context/Notification.context";

const router = createBrowserRouter([
  {
    path: "",
    element: <MainLayout />,
    loader: () => {
      return <SpinFC />;
    },
    children: [
      {
        path: "",
        element: <HomePage />,
        loader: () => {
          return <SpinFC />;
        },
      },
    ],
  },
]);

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <NotificationContextProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </NotificationContextProvider>
  );
};

export default App;
