/** @format */

export const useApiUrl = () => {
  const env = process.env.REACT_APP_NODE_ENV;

  if (env === "development") {
    return "http://localhost:8080";
  }

  if (env === "uat") {
    return "https://uat-rules-engine.payplan.ai";
  }

  if (env === "prod" || env === "production") {
    return "https://rules-engine.payplan.ai";
  }

  return "https://staging.rules-engine.payplan.ai";
};
