import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Drawer, Space, Upload, UploadProps } from "antd";
import { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
import { FC, useEffect, useState } from "react";
import { useNotify } from "../hooks/useNotify";
import { useUploadDmn } from "../api/upload";

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

type UploadDmnFileProps = {
  filename: string;
  name: string;
  open: boolean;
  setOpen: (val: boolean) => void;
};
export const UploadDmnFile: FC<UploadDmnFileProps> = ({
  filename,
  name,
  open,
  setOpen,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tempFile, setTempFile] = useState<RcFile | string | Blob>();
  const [imageUrl, setImageUrl] = useState<string>();

  const { showSuccess, showError } = useNotify();

  const { upload, isLoading: loadingUpload } = useUploadDmn(
    () => {
      setOpen(false);
      showSuccess(`DMN was updated successfully. Let's test it!`);
    },
    () => {
      showError("Failed uploading DMN.");
    }
  );

  const handleFileUpload = async (file: RcFile) => {
    console.log("file", file);

    getBase64(file, (url: string) => {
      setLoading(false);
      setImageUrl(url);
    });
  };

  useEffect(() => {
    if (!!tempFile) {
      handleFileUpload(tempFile as RcFile);
    }
  }, [tempFile]);

  const uploadButton = (
    <>
      {!!tempFile ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </>
  );

  const onConfirm = () => {
    console.log("grab tempFile and upload it to the server...");

    const formData = new FormData();
    formData.append("file", tempFile as Blob);

    upload(filename, formData);
  };

  return (
    <Drawer
      title={`Edit "${name ?? ""}" file`}
      placement="bottom"
      onClose={() => setOpen(false)}
      open={open}
      extra={
        <Space>
          <Button
            type={"primary"}
            loading={loadingUpload}
            disabled={!tempFile}
            onClick={() => onConfirm()}
          >
            Update DMN
          </Button>
        </Space>
      }
    >
      <Alert
        type={"warning"}
        message={"Careful!"}
        description={
          "This will override the existing DMN file. Keep in mind that this action will impact the rest of the services/users using this DMN model."
        }
      />
      <Upload
        name={filename}
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={({ file }) => {
          setTempFile(file);
          return "";
        }}
        maxCount={1}
        accept=".dmn"
      >
        <div
          style={{
            width: 220,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt={filename}
              style={{ width: "100%", padding: "16px" }}
            />
          ) : (
            uploadButton
          )}
        </div>
      </Upload>
    </Drawer>
  );
};

export default UploadDmnFile;
