import axios from "axios";
import { useApiUrl } from "../hooks/useApiUrl";
import { useState } from "react";

export const useDownloadDmn = () => {
  const apiUrl = useApiUrl();
  const [isLoading, setLoading] = useState<boolean>(false);

  const download = async (filename: string) => {
    setLoading(true);

    try {
      const resp = await axios.request({
        method: "GET",
        responseType: "blob",
        url: `${apiUrl}/v2/dmn/${filename}/download`,
        headers: {
          "Content-Type": "application/xml",
        },
      });

      console.log("data", resp.data);

      const href = URL.createObjectURL(resp.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", filename); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } finally {
      setLoading(false);
    }
  };

  return {
    download,
    isLoading,
  };
};
