import React from "react";
import { Layout, Menu, Typography } from "antd";
import styled from "styled-components";
import { Link, Outlet } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";

const { Header, Content, Footer } = Layout;

const LayoutWrapper = styled(Layout)`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const ContentWrapper = styled(Content)`
  padding: 24px;
  overflow-y: auto;
`;

const FooterWrapper = styled(Content)`
  text-align: center;
`;

const HeaderWrapper = styled(Header)`
  display: flex;
  align-items: center;
`;

const MainLayout = () => {
  return (
    <LayoutWrapper>
      <HeaderWrapper>
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          items={[
            {
              key: "1",
              label: "DMN Playground",
            },
            {
              key: "2",
              label: (
                <>
                  <a
                    href="https://camunda.com/download/modeler/"
                    target="_blank"
                  >
                    Download Camunda Modeler
                  </a>
                  <DownloadOutlined />
                </>
              ),
            },
          ]}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
    </LayoutWrapper>
  );
};

export default MainLayout;
