import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { apiCall } from "./common";
import { useApiUrl } from "../hooks/useApiUrl";
import { useNotify } from "../hooks/useNotify";
import { CreateDmnPayload, DMN, ExecuteDmnPayload } from "../types";

export const useDmns = () => {
  const apiUrl = useApiUrl();

  const { isError, isLoading, data } = useQuery<DMN[]>({
    queryFn: () => apiCall("GET", `${apiUrl}/v2/dmn`),
    queryKey: ["dmns"],
  });

  return {
    isError,
    isLoading,
    data,
  };
};

export const useExecuteDmn = () => {
  const apiUrl = useApiUrl();
  const client = useQueryClient();
  const { showError, showSuccess } = useNotify();

  const { isError, error, isLoading, mutate, data } = useMutation({
    mutationFn: ({ dmnFile, ctx, debug, showDesc }: ExecuteDmnPayload) =>
      apiCall("POST", `${apiUrl}/v2/dmn/${dmnFile}/execute`, ctx, {
        debug,
        showDesc,
      }),

    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["dmns"] });
      showSuccess("DMN was executed successfully.");
    },
    onError: (error: any) => {
      const msgError = error?.response?.data?.message;
      showError(
        msgError ?? "Error when executing the DMN. Contact administrators."
      );
    },
  });

  return {
    isError,
    isLoading,
    data,
    error,
    executeDmn: (payload: ExecuteDmnPayload) => mutate(payload),
  };
};

export const useCreateDmn = (onSuccess?: Function) => {
  const apiUrl = useApiUrl();
  const client = useQueryClient();
  const { showError, showSuccess } = useNotify();

  const { isError, isLoading, mutate, data } = useMutation({
    mutationFn: (payload: CreateDmnPayload) =>
      apiCall("PUT", `${apiUrl}/v2/dmn`, payload),

    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["dmns"] });
      showSuccess("DMN was created successfully, upload the file now...");
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: () => {
      showError("Error when creating the DMN. Contact administrators.");
    },
  });

  return {
    isError,
    isLoading,
    data,
    createDmn: (payload: CreateDmnPayload) => mutate(payload),
  };
};

export const useDeleteDmn = (onSuccess?: Function) => {
  const apiUrl = useApiUrl();
  const client = useQueryClient();
  const { showError, showSuccess } = useNotify();

  const { isError, isLoading, mutate, data } = useMutation({
    mutationFn: (filename: string) =>
      apiCall("DELETE", `${apiUrl}/v2/dmn/${filename}`),

    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["dmns"] });
      showSuccess("DMN was removed successfully.");
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: () => {
      showError("Error when removing the DMN. Contact administrators.");
    },
  });

  return {
    isError,
    isLoading,
    data,
    deleteDmn: (filename: string) => mutate(filename),
  };
};
