import axios from "axios";

type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export const apiCall = async (
  method: HttpMethod,
  path: string,
  payload?: any,
  params?: any,
  options?: any
) => {
  const optionalToken = options?.token;

  const headers: Record<string, any> = { "Content-Type": "application/json" };

  if (optionalToken) {
    headers["Authorization"] = `Bearer ${optionalToken}`;
  } else {
    // Get token from cookies
    const cookies = document.cookie;
    const tokenCookie = cookies.split("; ").find((r) => r.startsWith("token"));
    if (tokenCookie) {
      const cookieParts = tokenCookie?.split("=");
      if (cookieParts.length >= 1) {
        headers["Authorization"] = `Bearer ${cookieParts[1]}`;
      }
    }
  }

  const resp = await axios.request({
    method,
    url: path,
    data: payload,
    params: params,
    headers,
  });

  return resp.data;
};
